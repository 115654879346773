import {Controller} from "@hotwired/stimulus"
import {template} from "lodash";

export default class extends Controller {
  static targets = [
    "locEnPlaceInput",
    "depositTypeInput",
    "montantActuelProprioInput",
    "montantActuelOqoroInput",
    "leaseDepositWrapper",
    "montantActuelOqoroWrapper",
    "montantActuelProprioWrapper",
  ]

  connect() {
  }

  showMontantLeaseDeposit(e) {
    let locEnPlaceValue = this.locEnPlaceInputTarget.checked;
    let depositTypeValue = this.depositTypeInputTarget['form--collection-select--component'].value;
    this.hideAndResetMontantsDeposit();
    if (locEnPlaceValue === true && depositTypeValue == 1) {
      this.showMontantOqoro();
    }
  }

  showMontantOqoro() {
    this.leaseDepositWrapperTarget.classList.remove("hidden");
    this.montantActuelOqoroWrapperTarget.classList.remove("hidden-important");
  }

  hideAndResetMontantsDeposit(e) {
    this.montantActuelProprioInputTarget.value = null;
    this.montantActuelOqoroInputTarget.value = null;
    if (!this.leaseDepositWrapperTarget.classList.contains("hidden")) {
      this.leaseDepositWrapperTarget.classList.add("hidden");
    }
    if (!this.montantActuelOqoroWrapperTarget.classList.contains("hidden-important")) {
      this.montantActuelOqoroWrapperTarget.classList.add("hidden-important");
    }
    if (!this.montantActuelProprioWrapperTarget.classList.contains("hidden-important")) {
      this.montantActuelProprioWrapperTarget.classList.add("hidden-important");
    }
  }
}
